import React from 'react'
import Spinner from './spinner-speed.gif'

function spinner1() {
  return (
    <div>
        <img src={Spinner} width={36} height={36}/>
    </div>
  )
}

export default spinner1