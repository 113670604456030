const data =[{
    "rapidEventId":1,
    "time":"2022-10-20",
    "homeTeam":"Man U",
    "awayTeam":"Man City",
     "goalOdds":"4+90",
     "bodyOdds":"2-40"
},
{
    "rapidEventId":2,
 "time":"2022-10-20",
 "homeTeam":"Man U",
 "awayTeam":"Man City",
 "goalOdds":"4+90",
 "bodyOdds":"2-40"
},
{
    "rapidEventId":3,
 "time":"2022-10-20",
 "homeTeam":"Man U",
 "awayTeam":"Man City",
 "goalOdds":"4+90",
 "bodyOdds":"2-40"
},
{
    "rapidEventId":4,
 "time":"2022-10-20",
 "homeTeam":"Man U",
 "awayTeam":"Man City",
 "goalOdds":"4+90",
 "bodyOdds":"2-40"
},
{
    "rapidEventId":5,
 "time":"2022-10-20",
 "homeTeam":"Man U",
 "awayTeam":"Man City",
 "goalOdds":"4+90",
 "bodyOdds":"2-40"
},
{
    "rapidEventId":6,
 "time":"2022-10-20",
 "homeTeam":"Man U",
 "awayTeam":"Man City",
 "goalOdds":"4+90",
 "bodyOdds":"2-40"
},
{
    "rapidEventId":7,
 "time":"2022-10-20",
 "homeTeam":"Man U",
 "awayTeam":"Man City",
 "goalOdds":"4+90",
 "bodyOdds":"2-40"
},
{
    "rapidEventId":8,
 "time":"2022-10-20",
 "homeTeam":"Man U",
 "awayTeam":"Man City",
 "goalOdds":"4+90",
 "bodyOdds":"2-40"
},
{
    "rapidEventId":9,
 "time":"2022-10-20",
 "homeTeam":"Man U",
 "awayTeam":"Man City",
 "goalOdds":"4+90",
 "bodyOdds":"2-40"
},
{
    "rapidEventId":1,
 "time":"2022-10-20",
 "homeTeam":"Man U",
 "awayTeam":"Man City",
 "goalOdds":"4+90",
 "bodyOdds":"2-40"
},{
    "rapidEventId":10,
 "time":"2022-10-20",
 "homeTeam":"Man U",
 "awayTeam":"Man City",
 "goalOdds":"4+90",
 "bodyOdds":"2-40"
},
{
    "rapidEventId":11,
 "time":"2022-10-20",
 "homeTeam":"Man U",
 "awayTeam":"Man City",
 "goalOdds":"4+90",
 "bodyOdds":"2-40"
},
{
    "rapidEventId":12,
 "time":"2022-10-20",
 "homeTeam":"Man U",
 "awayTeam":"Man City",
 "goalOdds":"4+90",
 "bodyOdds":"2-40"
},
{
    "rapidEventId":13,
 "time":"2022-10-20",
 "homeTeam":"Man U",
 "awayTeam":"Man City",
 "goalOdds":"4+90",
 "bodyOdds":"2-40"
},
{
    "rapidEventId":14,
 "time":"2022-10-20",
 "homeTeam":"Man U",
 "awayTeam":"Man City",
 "goalOdds":"4+90",
 "bodyOdds":"2-40"
},{
    "rapidEventId":15,
 "time":"2022-10-20",
 "homeTeam":"Man U",
 "awayTeam":"Man City",
 "goalOdds":"4+90",
 "bodyOdds":"2-40"
}
,{
    "rapidEventId":16,
 "time":"2022-10-20",
 "homeTeam":"Man U",
 "awayTeam":"Man City",
 "goalOdds":"4+90",
 "bodyOdds":"2-40"
}
]

module.exports = {data}