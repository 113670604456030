import React from 'react'
import Spinner from './Spin-4s-200px.gif'

function spinner() {
  return (
    <div>
         <img src={Spinner} width={20} height={20}/>
    </div>
  )
}

export default spinner